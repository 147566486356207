<template>
	<BaseLoading v-if="edit.isLoading" is-full-page />
	<PromotionBannerKeysForm v-else :default-data="edit.data" is-edit-mode />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PromotionBannerKeysForm from '@/components/PromotionBannerKeysForm.vue';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'PromotionBannerKeysEdit',
	components: {
		PromotionBannerKeysForm,
	},

	computed: {
		...mapState('promotionBannerKeys', {
			edit: 'edit',
		}),
	},

	async created() {
		this.setBreadcrumbs([
			{
				title: 'Promotion banner Key',
				route: { name: ROUTE_NAME.PROMOTION_BANNER_KEY_LIST },
			},
		]);

		await this.getPromotionBannerKey(this.$route.params.id);
	},

	methods: {
		...mapActions({
			setBreadcrumbs: 'breadcrumb/setBreadcrumbs',
			getPromotionBannerKey: 'promotionBannerKeys/getPromotionBannerKey',
		}),
	},
};
</script>
